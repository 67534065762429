@import '~@bikemap/scss/settings/settings';

.page-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: $header-height;
    background-color: #fff;
    box-shadow: $box-shadow-low;

    .page-header-home-link {
        display: inline-block;
        padding: 0.8rem 1rem 0.5rem;
        line-height: 0;

        svg {
            width: 10rem;
            height: 1.9rem;
        }
    }

    .page-header-toggle-btn,
    .page-header-search-link,
    .page-header-back-btn {
        position: absolute;
        top: 0;
        background: none;
        border: 0;
        color: $text-color;
    }

    .page-header-toggle-btn {
        left: 0;
        padding: 0.5rem;

        .i-menu,
        .i-menu-close {
            font-size: 33px;
        }
    }

    .page-header-search-link,
    .page-header-back-btn {
        padding: 0.8rem 0.8rem 0.58rem;
    }

    .page-header-search-link {
        right: 0;
    }

    .page-header-back-btn {
        left: 0;
    }

    .page-header-content {
        display: none;
    }

    @media (max-width: $screen-sm-max) {
        .page-header-cancel-btn {
            display: none;
        }
    }

    @media (min-width: $screen-md) {
        justify-content: space-between;
        padding-right: 1rem;

        .page-header-home-link {
            border-right: 1px solid $gray-superlight;
        }

        .page-header-toggle-btn,
        .page-header-search-link,
        .page-header-back-btn {
            display: none;
        }

        .page-header-content {
            display: block;
            align-self: center;
            width: 25rem;
            margin: 0 auto 0 1rem;

            > * {
                margin: 0;
            }
        }

        > .btn {
            align-self: center;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .main-navigation {
            margin-left: 1rem;
        }
    }
}
