@import 'icon-gradient';
@import 'components/home/feature-icon';

/*
The SVG gradient must be present on the page: Template 'premium/_svg/_icon_gradient.svg'
*/

.gift-circled {
    .circle {
        stroke: url('#premium-icon-gradient') $brand-premium;
        fill: #fff;
    }

    .gift-icon {
        fill: url('#premium-icon-gradient') $brand-premium;
    }
}
