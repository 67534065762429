@import '~@bikemap/scss/settings/colors';

@mixin visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

@keyframes loading-spinner-animation {
    to {
        transform: rotate(360deg);
    }
}

@mixin loading-spinner($color: rgba(255, 255, 255, 0.5)) {
    min-width: 24px;
    min-height: 24px;
    color: transparent;
    cursor: default;

    &::before {
        content: 'Loading...';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        margin-top: -9px;
        margin-left: -9px;
    }

    &:active {
        pointer-events: none;
    }

    &:not(:required)::before {
        animation: loading-spinner-animation 0.6s linear infinite;
        content: '';
        border-radius: 50%;
        border: 2px solid transparent;
        border-color: transparent transparent $color $color;
    }
}
