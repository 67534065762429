$highlight: lighten($highlight-premium, 3%);
$normal: $brand-premium;

#premium-icon-gradient {
    // If styling stop color via CSS is not supported
    --highlight: #{$highlight};
    --normal: #{$normal};

    // If CSS variables are not supported
    .stop-highlight {
        stop-color: $highlight;
    }

    .stop-normal {
        stop-color: $normal;
    }
}
