/*
Using the currentColor variable on the text path in the SVG allows us to cascade two different
fill colors into the shadow DOM of the <use> element.
*/

.bikemap-logo {
    color: $brand-primary;
    fill: $brand-primary;
}

.bikemap-logo-gray {
    color: $gray-dimmed;
    fill: $gray-dimmed;
}
