.apps-badges {
    // Negative margin in combinatino with margin-left on children makes it work with centered text
    margin-left: -0.4em;

    a {
        display: inline-block;
        margin-left: 0.4em;
    }

    .app-store-badge,
    .google-play-badge {
        height: 2.5em;
        // Only render when in viewport
        content-visibility: auto;
    }

    .app-store-badge {
        width: 7.5em;
    }

    .google-play-badge {
        width: 8.5em;
    }

    @media (min-width: $screen-sm) {
        .app-store-badge,
        .google-play-badge {
            height: 2.8em;
        }

        .app-store-badge {
            width: 8.3em;
        }

        .google-play-badge {
            width: 9.5em;
        }
    }
}
