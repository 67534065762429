/*
Select dropdown field for language setting.
*/
.language-field {
    .flag-de,
    .flag-uk {
        width: 1.5rem;
        height: 1.125rem;
        margin-right: 0.5rem;
        vertical-align: text-top;
    }
}
