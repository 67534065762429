.multi-search {
    position: relative;

    .multi-search-field {
        padding: 0.5em 2.6em 0.3em 2.8em;
    }

    .multi-search-link {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5em 0.1em 0 0.6em;
    }

    .reset {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        padding: 0.8em 0.6em 0.7em;
        line-height: 0.5;
        background: none;
        border: 0;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            color: $brand-primary;
        }

        &:disabled {
            display: none;
        }
    }

    .dropdown-list {
        height: 0;
        z-index: $z-index-multi-search-dropdown;

        small {
            color: $text-secondary-color;
            margin: 0 0.1em;
        }
    }

    &:focus-within .results,
    .multi-search-field:focus ~ .results,
    .results:focus,
    .results:hover {
        height: auto;
    }
}
