@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/mixins/visibility';

.form-field {
    position: relative;
    margin-bottom: $form-element-margin-bottom;
    text-align: left;

    &:not(.no-label) {
        label {
            margin-bottom: $label-margin-bottom;
        }
    }

    &.no-label {
        label {
            @include visually-hidden;
        }
    }

    .form-field-error {
        font-size: $font-size-small;
        color: $brand-danger;
        margin-top: 0.2rem;
        line-height: $line-height-tight;
    }
}
