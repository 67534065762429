/*
Additional variants of text elements.
*/

.bodytext,
%bodytext {
    font-size: $font-size-bodytext-small;

    @media (min-width: $screen-md) {
        font-size: $font-size-bodytext-large;
    }
}

.lead,
%lead {
    font-size: 1.14rem;
    line-height: $line-height-compact;

    @media (min-width: $screen-md) {
        font-size: 1.4rem;
    }
}

.link-subtle,
.link-subtle:hover {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
}

.quoted,
%quoted {
    &::before {
        content: open-quote;
        color: $brand-primary;
        font-size: 7rem;
        font-weight: bold;
        line-height: 0.7;
        display: block;

        html:lang(de) & {
            // In German language the opening quotation marks are at the bottom
            line-height: 0;
            margin-bottom: 5.5rem;
        }
    }

    &::after {
        // Without this closing quote there would be issues with the next opening quote
        content: close-quote;
        visibility: hidden;
    }
}
