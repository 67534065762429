/*
Wrapper for an input field with inline label on one or both sides.
*/

.input-labeled {
    position: relative;

    input {
        &:not(:first-child) {
            padding-left: 2.8rem;
        }

        &:not(:last-child) {
            padding-right: 2.8rem;
        }
    }

    // Inline elements based on their position
    > :not(input) {
        position: absolute;
        top: 0;

        &:first-child {
            left: 0;
        }

        &:last-child {
            right: 0;
        }
    }
}
