@import '~@bikemap/scss/settings/settings';

.premium-badge {
    .symbol {
        fill: #fff;
    }

    .below {
        fill: $premium-color-2;
    }

    .circle {
        fill: $premium-color-1;
    }

    .border {
        fill: $premium-color-3;
    }
}
