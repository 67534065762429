@import 'typography/variants';
@import 'layout/wrappers';

section {
    padding: 3rem 0 4rem;
    background-color: #fff;

    aside {
        color: $text-secondary-color;
        fill: $text-secondary-color;
        text-align: center;
        max-width: 25rem;
        margin: 0 auto;

        small {
            display: block;
            line-height: 1.3;
        }
    }
}

.section-title {
    @extend %wrapper-text;

    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.section-lead {
    @extend %lead;
    @extend %wrapper-text;

    text-align: center;

    .section-title + & {
        margin-top: -2.2rem;
    }

    + .section-content {
        margin-top: 2rem;
    }
}

.section-content {
    &:first-child {
        margin-top: 1rem;
    }
}

.section-cta {
    @extend %wrapper-text;

    text-align: center;
    margin: 4rem auto 1rem;
}
