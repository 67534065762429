@import 'sections/hero/primary';

.section-hero-with-screen,
%section-hero-with-screen {
    @extend %section-hero-primary;

    overflow: visible;
    display: flex;

    .hex1 {
        right: 60%;
        bottom: 7%;
    }

    .hex2 {
        left: 64%;
        top: 5%;
    }

    .apps-badges {
        margin-bottom: 1rem;
    }

    .app-screen {
        width: 14rem;
        margin: 0 auto -14rem;
    }

    .hero-content {
        padding-left: 2rem;
    }

    @media (min-width: $screen-sm) {
        .hero-content {
            position: relative;
            padding-right: 26rem;
        }

        .apps-badges {
            margin-top: 3rem;
            margin-bottom: 2rem;
            font-size: 1.2rem;
        }

        .lead {
            max-width: 36rem;
        }

        .app-screen {
            width: 18rem;
            position: absolute;
            top: -4.5rem;
            right: 2rem;
            left: auto;
            margin: 0;
        }
    }

    @media (min-width: $screen-md) {
        .app-screen {
            width: 22rem;
            right: 0;
        }
    }
}
