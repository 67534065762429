@import './colors';

/*
Mixins for buttons and button-like elements.
*/

// Basic styles for most buttons
@mixin button-basics {
    position: relative;
    display: inline-block;
    font-weight: bold;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    user-select: none;
    border: 0;
    white-space: nowrap;
    line-height: normal;

    &:active,
    &:focus {
        outline: none;
    }
}

@mixin colored-button-state($background) {
    @include content-white;

    background: $background;
}

@mixin button-hover-focus($background) {
    &:not(:disabled):not(.disabled):not(.active) {
        &:hover,
        &:focus {
            background: $background;
        }
    }
}

@mixin colored-button($background) {
    @include colored-button-state($background);
    @include button-hover-focus(darken($background, 10%));
}

/* Currently reused in several places */
@mixin primary-button() {
    background-color: $actionColor-primary;
    color: $onColor-primary;

    &:not(:disabled):not(.disabled):not(.active) {
        &:hover,
        &:focus {
            background-color: $actionColor-secondary;
        }

        &:active {
            background-color: $actionColor-tertiary;
        }
    }

    &.disabled,
    &:disabled {
        background-color: $color-neutral-tertiary;
    }

    &.btn-inverse:not(.disabled):not(:disabled) {
        @include colored-button(#fff);

        color: $actionColor-primary;
    }
}

@mixin button-width-variants($padding-horizontal) {
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;

    &.btn-wider {
        padding-left: $padding-horizontal * 1.5;
        padding-right: $padding-horizontal * 1.5;
    }

    &.btn-narrower {
        padding-left: $padding-horizontal * 0.4;
        padding-right: $padding-horizontal * 0.4;
    }
}

@mixin button-icon-spacing($base-spacing) {
    $margin-correction: -($base-spacing * 1.2);

    [class^='i-'] {
        margin: 0 $margin-correction;

        + span {
            margin-left: $base-spacing - $margin-correction;
        }
    }

    span + [class^='i-'] {
        margin-left: $base-spacing;
    }
}
