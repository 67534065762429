@import '~@bikemap/scss/settings/settings';

.root {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: $gray-ultralight;

  .close {
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    margin: 0 0.3rem 0 -0.5rem;
  }
}

.app-icon-holder {
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
}

.content {
  padding: 0 1rem;
  flex-grow: 1;

  h4, p {
    margin: 0;
    line-height: $line-height-tight;
  }
}
