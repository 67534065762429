@import 'components/home/icon-gradient';

/*
The SVG gradient must be present on the page: Template 'home/_svg/_icon_gradient.svg'
*/

.feature-icon,
%feature-icon {
    width: 6rem;
    height: 6rem;

    .shade {
        fill: lighten($brand-primary, 20%);
        fill-opacity: 0.35;
    }

    .stroke {
        fill: none;
        stroke: url('#icon-gradient') $brand-primary;
    }

    &,
    .fill {
        fill: url('#icon-gradient') $brand-primary;
    }

    .background {
        // Paths sometimes have a white background to hide other paths. With this class
        // the color can be changed to match the actual background.
        fill: #fff;
    }
}
