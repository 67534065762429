/*
Deprecated - please define spacing directly. However these classes are still widely used.
*/

.mt {
    margin-top: 1rem;
}

.mb {
    margin-bottom: 1rem;
}

.pt {
    padding-top: 1rem;
}

.pb {
    padding-bottom: 1rem;
}
