@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/utils/overlays';

/*
Displays the user's avatar image.
*/
.avatar {
    position: relative;
    width: 2.2em;
    height: 2.2em;

    .avatar-img-container {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: $box-shadow-low;
        background-color: $gray-ultralight;
    }

    &.big .avatar-img-container {
        border-width: 0.1em;
    }

    img {
        @extend %overlay;
        object-fit: cover;
    }

    .i-helmet {
        font-size: 2em;
        margin: -0.1em 0 0 0.05em;
        opacity: 0.35;
    }

    .premium-badge {
        width: 1.2em;
        height: 1.2em;
        position: absolute;
        right: -0.1em;
        bottom: -0.2em;
    }
}
