@import 'layout/wrappers';
@import 'utils/overlays';

/*
Basic styles for all hero sections.
*/

.section-hero,
%section-hero {
    position: relative;
    overflow: hidden;
    padding: 0;

    .hero-image {
        @extend %overlay;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        ~ .hero-content {
            // Content in front of the image
            color: #fff;
            text-shadow: 0 0 0.3em rgb(0 0 0 / 40%);

            .btn {
                text-shadow: none;
            }
        }
    }

    .hero-content {
        position: relative;
        margin-top: 5rem;
        margin-bottom: 3rem;
    }

    .lead {
        color: inherit;
        margin-bottom: 2rem;
    }

    @media (min-width: $screen-sm) {
        .hero-content {
            margin-top: 6rem;
            margin-bottom: 3rem;
        }

        .lead {
            margin-bottom: 2.7rem;
        }
    }
}
