@charset "UTF-8";
@import './close-button';
@import 'utils/overlays';

// Modal
// =====

// Container that the modal scrolls within
.modal {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal;
    -webkit-overflow-scrolling: touch;
    text-align: center;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    &.fade .modal-dialog {
        transition: all $transition-fast ease-out;
    }
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    max-width: 100%;
    position: relative;
    padding: 1rem 0.5rem;

    &:not(.fullscreen) {
        @media (min-width: $max-width-block-form) {
            max-width: $max-width-block-form;
        }
    }

    @media (min-width: $screen-sm) {
        padding: 3rem 0.5rem;
    }
}

@keyframes modal-opening {
    from {
        transform: scale(0.6);
        opacity: 0;
    }

    50% {
        transform: scale(0.6);
        opacity: 0;
    }

    to {
        transform: none;
        opacity: 1;
    }
}

.modal-content {
    position: relative;
    background-color: #fff;
    border-radius: $border-radius-large;
    box-shadow: 0 1px 30px rgb(0 0 0 / 50%);
    background-clip: padding-box;
    animation: modal-opening 0.3s ease-out;
    max-width: 100%;

    // Remove focus outline from opened modal
    outline: 0;

    .modal.full & {
        width: 100vw;
    }
}

.modal .modal-content.loading-spinner {
    @extend %overlay-after;

    color: inherit;

    &::before {
        border-color: transparent transparent $brand-primary $brand-primary;
        z-index: $simple-top;
    }

    &::after {
        background-color: #fff;
        opacity: 0.7;
    }
}

@keyframes backdrop-fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes backdrop-fade-out {
    0% {
        opacity: 1;
        max-height: 100vh;
    }

    99% {
        opacity: 0;
        max-height: 100vh;
    }
    100% { max-height: 0; }
}

#modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal;
    background-color: $modal-backdrop-color;
    opacity: 0;

    &.fade-in {
        animation: 0.3s linear backdrop-fade-in;
        opacity: 1;
    }

    &.fade-out {
        animation: 0.3s linear backdrop-fade-out;
        max-height: 0;
    }

    // Fade for backdrop
    &.fade { opacity: 0; }
    &.in { opacity: 1; }
}

.modal-lead {
    text-align: left;
    margin-top: 1rem;
    line-height: 1.5;
    color: $text-secondary-color;
}

.modal-header {
    padding: 2.2rem $base-padding-horizontal 0;

    .modal-icon {
        font-size: 4rem;
        margin-bottom: 0.5rem;
    }

    .modal-title {
        margin: 0 0 1rem;
        font-size: 2rem;
    }

    .modal-logo {
        margin: 0 auto;

        svg {
            height: 28px;
            width: 170px;
        }
    }

    .close-btn {
        position: absolute;
        right: 0;
        top: 0;
    }

    @media (max-width: $screen-xxs-max) {
        .modal-icon {
            max-width: 100%;
            max-height: 8rem;
        }

        .modal-title {
            font-size: 1.8rem;
            margin-bottom: 0;
        }
    }
}

.modal-body {
    position: relative;
    padding: 0.5rem $base-padding-horizontal;

    .modal-lead {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }

    > :last-child {
        margin-bottom: 0;
    }

    @media (max-width: $screen-xxs-max) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.modal-footer {
    padding: 0.5rem $base-padding-horizontal 1.6rem;

    &:empty {
        padding-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    .btn {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 0.5rem;
            display: inline-flex;
            width: 100%;
        }
    }

    @media (min-width: $screen-sm) {
        .btn + .btn {
            margin-left: 0.5rem;
        }

        .btn-group .btn + .btn {
            margin-left: -1px;
        }

        .btn-block + .btn-block {
            margin-left: 0;
        }
    }

    @media (max-width: $screen-xxs-max) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
