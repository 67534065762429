/*
General styles for icons, based on icomoon output.
*/

@import '~@bikemap/icons/variables';

$icomoon-font-path: '~@bikemap/icons/fonts';
$icon-size: 1.6em;
$icon-size-sm: 1.4em;
$icon-size-lg: 1.8em;
$icon-size-xl: 3em;

@import '~@bikemap/icons/style';

[class^='i-'],
[class*=' i-'] {
    display: inline-block;
    font-size: $icon-size;
    vertical-align: -0.2em;
}

.i-sm,
%i-sm {
    font-size: $icon-size-sm;
}

.i-lg,
%i-lg {
    font-size: $icon-size-lg;
}

.i-xl {
    font-size: $icon-size-xl;
}
