/*
All global definitions regarding typography.
*/

$font-size-base: 15px;
$font-size-small: 0.9rem;
$font-size-tiny: 0.7rem;

$font-family-base: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;

$text-color: $gray-dark;
$text-secondary-color: $gray-dimmed;
$link-color: $brand-primary;

$line-height-tight: 1.25;
$line-height-compact: 1.5;
$line-height-base: 1.7;

$font-size-bodytext-small: 1rem;
$font-size-bodytext-large: 1.2rem;

$font-size-h5-small: $font-size-bodytext-small;
$font-size-h5-large: $font-size-bodytext-large;
