@import '~@bikemap/scss/settings/settings';

$badge-height: 2.4em;

.badge,
.badge-primary,
.badge-premium {
    border: 0;
    font-size: inherit;
    display: inline-block;
    height: auto;
    text-align: center;
    line-height: 1;
    border-radius: $badge-height * 0.5;
    padding: (($badge-height - 1em) * 0.5) 1.4em;
    font-weight: 500;
}

button,
a {
    &.badge,
    &.badge-primary,
    &.badge-premium {
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            border: 1px solid;
            margin: -1px;
        }

        &:hover {
            box-shadow: $box-shadow-low;
        }
    }
}

.badge {
    color: $text-color;
    background: $gray-superlight;
}

.badge-primary {
    color: #fff;
    background: $gradient-primary;
}

.badge-premium {
    color: $brand-premium;
    background: $transparent-dark;
}
