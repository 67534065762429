@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/utils/loading-spinner';

.input-field {
    &.loading-spinner {
        color: $text-color;

        &::before {
            border-bottom-color: $brand-primary;
            border-left-color: $brand-primary;
            width: 1em;
            height: 1em;
            top: 1.3em;
            right: 0.7em;
            left: auto;
        }
    }

    .input-icon {
        position: absolute;
        bottom: 0.36rem;
        left: 0.7rem;
        pointer-events: none;

        ~ input {
            padding-left: 2.5rem;
        }
    }

    .input-ctrl {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;

        &.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.minimal-button {
            right: 0.4rem;
            top: 0.35rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            // Precise size so that it's exactly 25px which browser can render as a perfect circle
            width: 1.667rem;
            height: 1.667rem;
            padding: 0;
        }

        ~ input {
            padding-right: 2.5rem;
        }
    }

    &:not(.no-label) {
        &.loading-spinner::before {
            top: 3.3rem;
        }

        .input-ctrl {
            margin-top: 2rem;
        }
    }

    .input-secondary {
        border-radius: 1.2rem;
    }
}
