@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/mixins/form';

.select-dropdown-button {
    @include form-element-basics;

    position: relative;
    cursor: pointer;
    text-align: left;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.3rem);
    padding-right: 2rem;

    .i-caret-down {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
}
