.btn.close-btn,
%close-btn {
    border: 0;
    background: none;
    padding: 0.3rem 1rem;
    margin: 0.6rem;

    @media (min-width: $screen-md) {
        opacity: 0.5;

        &:focus,
        &:hover {
            opacity: 1;
        }
    }
}
