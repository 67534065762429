@import '~@bikemap/scss/settings/settings';

.root {
    position: relative;
    background-color: #fff;
    padding: 1rem;
    border-radius: $border-radius-large;
    box-shadow: $box-shadow-base;
    max-width: 25rem;
}

.language {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
}

.explanation {
    background-color: $gray-superlight;
    padding: 0.5rem;
    border-radius: $border-radius-large;
    font-size: $font-size-small;
    max-height: 13rem;
    overflow-y: auto;
    line-height: $line-height-tight;
}

.checkboxes {
    margin: 1rem 0;
}

.requiredAddition {
    color: $text-secondary-color;
}

.learnMoreParagraph {
    font-size: $font-size-small;
    color: $text-secondary-color;
    line-height: $line-height-tight;
}

.buttons {
    display: flex;
    gap: 1rem;

    > button {
        flex-grow: 1;
        white-space: normal;
        flex-basis: 50%;
        box-sizing: content-box;
        padding: calc(0.7rem - 2px) 1.8rem;
        height: auto;
    }
}
