/*
Text color variants.
*/
.text-primary {
    color: $brand-primary;
}

.text-secondary {
    color: $text-secondary-color;
}

.text-white {
    color: #fff;
}

.text-dark {
    color: $text-color;
}

.text-success {
    color: $brand-success;
}

.text-danger {
    color: $brand-danger;
}

.text-gold {
    color: $bikemap-gold;
}

.text-warning {
    color: $brand-warning;
}

.text-premium {
    color: $brand-premium;
}
