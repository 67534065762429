/*
Bikemap color palette.
*/

// Base colors
$bikemap-blue: #0873E7;
$bikemap-green: #34C759;
$bikemap-orange: #e77920;
$bikemap-red: #FF3B30;
$bikemap-gold: #ffc500;

// Logo
$bikemap-logo-bike: $bikemap-blue;
$bikemap-logo-text: #1a1818;

// Gray
$gray-darker: #273137;
$gray-dark: #1d4159;
$gray-dimmed: #5e7887;
$gray: #889eac;
$gray-light: #879ead;
$gray-lighter: #cbd6dd;
$gray-superlight: #e2eaef;
$gray-ultralight: #f0f3f5;

// Semantic purpose
$brand-primary: $bikemap-blue;
$brand-premium: $brand-primary;
$brand-success: $bikemap-green;
$brand-danger: $bikemap-red;
$brand-warning: $bikemap-orange;

$highlight-primary: #2bb8fd;
$highlight-premium: $highlight-primary;

$highlight-primary-weak: rgb(19 129 250 / 5%);

$premium-shadow-1: rgb(243 126 55 / 35%);
$premium-shadow-2: rgb(250 179 101 / 25%);

// Transparent colors
$transparent-dark: rgba($gray-darker, 0.8);
$transparent-dark-weak: rgba($gray-darker, 0.35);

$transparent-light-weak: rgba(#fff, 0.35);

// Gradients
$gradient-primary: linear-gradient(257deg, $highlight-primary, $brand-primary);
$gradient-gray: linear-gradient(to bottom, $gray-ultralight, $gray-lighter);

// Colors for specific usage
$modal-backdrop-color: $transparent-dark-weak;
$paper-background-color: #fff;

// 3rd parties
$facebook-color: #4267b2;
$google-color: #dc4e41;
$twitter-color: #00acee;
$apple-color: #000;

// Backgrounds
$body-bg: $gray-ultralight;

$map-background-color: #f8faef;

// POIs
$poi-obstacle-color: #f7b500;
$poi-hazard-color: #f7b500;
$poi-police-color: #53ace7;
$poi-general-color: #6236ff;
$poi-obstacle-color-light: #f6c273;
$poi-hazard-color-light: #f6c273;
$poi-police-color-light: #72bbeb;
$poi-general-color-light: #9796ec;
$poi-osm-color: #327baf;

$gradient-poi-obstacle: linear-gradient(45deg, $poi-obstacle-color, $poi-obstacle-color-light);
$gradient-poi-hazard: linear-gradient(45deg, $poi-hazard-color, $poi-hazard-color-light);
$gradient-poi-police: linear-gradient(45deg, $poi-police-color, $poi-police-color-light);
$gradient-poi-general: linear-gradient(45deg, $poi-general-color, $poi-general-color-light);

// Rebranded color tokens

$color-neutral-primary: #FFFFFF;
$color-neutral-secondary: #E6ECF2;
$color-neutral-tertiary: #CDDAE6;
$color-neutral-quartery: #9BB5CC;

$actionColor-primary: #0071E8;
$actionColor-secondary: #065CB9;
$actionColor-tertiary: #05458B;

$actionColor-onNeutral-primary: $bikemap-blue;
$actionColor-onNeutral-secondary: #065CB9;
$actionColor-onNeutral-tertiary: #05458B;
$actionColor-onNeutral-inactive: #83A2C0;

$accentColor-primary: $bikemap-green;

$dangerColor-primary: $bikemap-red;
$dangerColor-secondary: #CC2F26;
$dangerColor-tertiary: #CC2F26;

$onColor-primary: #FFFFFF;

$premium-color-1: #065CB9;
$premium-color-2: #064580;
$premium-color-3: #04294D;
