@charset "UTF-8";

@import '../settings/settings';
@import '../icons/icon';

.btn.btn-flat { // deprecated, use MinimalButton
    background-color: transparent;
    color: $brand-primary;
    fill: $brand-primary;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 1.3;
    flex-direction: column;
    height: auto;

    &:not(.disabled):not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            color: $highlight-primary;

            [class^='i-'] {
                color: $highlight-primary;
            }
        }

        &.btn-facebook,
        &.btn-google,
        &.btn-twitter,
        &.btn-ghost,
        &.btn-premium {
            background: none;
        }

        &.btn-ghost {
            color: #fff;
            fill: #fff;
        }

        &.btn-premium {
            color: $brand-premium;
            fill: $brand-premium;
        }
    }

    &.disabled,
    &:disabled {
        color: $text-secondary-color;
        fill: $text-secondary-color;
    }

    // icons appear on top
    [class^='i-'] {
        @extend %i-lg;

        display: block;
        flex-shrink: 0;
        margin: 0;

        &:not(:last-child) {
            margin: 0 0 0.3rem;
        }

        + span {
            margin: 0;
        }
    }

    // align the button's text content to the left
    &.align-left {
        margin-left: -11px;
    }

    .button-label {
        display: inline-block;
        margin-left: 4px;
    }
}
