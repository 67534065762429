@import '../mixins/visibility';

.sr-only {
    @include visually-hidden;
}

.hidden {
    display: none !important;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
    display: none;
}

@media (max-width: $screen-xs-max) {
    .visible-xs {
        display: block !important;
    }

    .visible-xs-inline-block {
        display: inline-block !important;
    }

    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .visible-sm {
        display: block !important;
    }

    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .visible-md {
        display: block !important;
    }

    .hidden-md {
        display: none !important;
    }
}

@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
    .visible-lg {
        display: block !important;
    }

    .hidden-lg {
        display: none !important;
    }
}

@media (min-width: $screen-xl) {
    .visible-xl {
        display: block !important;
    }

    .hidden-xl {
        display: none !important;
    }
}
