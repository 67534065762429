@import '../settings/z-index';

.bm-tooltip-right,
.bm-tooltip-left,
.bm-tooltip-top-left,
.bm-tooltip-top-right {
    position: relative;

    @media (hover: hover) {
        &:hover {
            &::before,
            &::after {
                position: absolute;
                background-color: $gray-dark;
                z-index: $simple-top;
                font-weight: normal;
            }

            &::before {
                border-radius: $border-radius-base;
                box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
                box-sizing: border-box;
                color: #fff;
                content: attr(data-tooltip);
                font-size: 1rem;
                margin: 0 3px;
                padding: 0 1em;
                white-space: nowrap;
                line-height: 2rem;
            }

            &::after {
                content: '';
                width: 1rem;
                height: 1rem;
                transform: rotate(45deg);
            }
        }
    }
}

.bm-tooltip-right {
    &::before {
        top: calc(50% - 1rem);
        left: calc(100% + 0.5rem);
    }

    &::after {
        top: calc(50% - 0.5rem);
        left: calc(100% + 0.4rem);
    }
}

.bm-tooltip-left {
    &::before {
        top: calc(50% - 1rem);
        right: calc(100% + 0.5rem);
    }

    &::after {
        top: calc(50% - 0.5rem);
        right: calc(100% + 0.4rem);
    }
}

.bm-tooltip-top-left {
    &::before {
        bottom: calc(100% + 0.5rem);
        left: calc(50% - 1.5rem);
    }

    &::after {
        bottom: 100%;
        left: calc(50% - 0.5rem);
    }
}

.bm-tooltip-top-right {
    &::before {
        bottom: calc(100% + 0.5rem);
        right: calc(50% - 1.5rem);
    }

    &::after {
        bottom: 100%;
        right: calc(50% - 0.5rem);
    }
}
