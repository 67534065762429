@import '~@bikemap/scss/settings/_settings.scss';

.input-labeled-password-field {
    position: relative;

    // stylelint-disable selector-no-qualifying-type
    input[type='password'],
    input[type='text'] {
        padding-right: 2.8rem;
    }

    .toggle-trigger {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 0.4rem 0.7rem;
    }

    .password-field-validation-helper {
        position: absolute;
        left: 0;
        right: 0;
        top: $form-element-height;
        width: 100%;
        color: $text-secondary-color;
        background-color: $paper-background-color;
        border-radius: $border-radius-base;
        box-shadow: $box-shadow-base;
        z-index: $form-field-validation-list;
        padding: 6px 12px 8px;

        ul {
            list-style: none;
            margin: 0;
        }

        li {
            margin: 0.2rem 0;

            i {
                font-size: 13px;
                margin: 1px 6px 0 0;
            }

            span {
                font-size: 14px;
            }
        }
    }
}
