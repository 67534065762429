/*
Displays the user's name and one additional info.
*/
@import '~@bikemap/scss/settings/settings';

.user-info {
    overflow: hidden;
    white-space: nowrap;

    &.layout-dark,
    .layout-dark & {
        p {
            opacity: 0.75;
        }
    }

    .user-name,
    p {
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        max-width: 100%;
        line-height: $line-height-tight;
    }

    .user-name {
        font-weight: bold;
    }

    p {
        font-weight: normal;
    }
}
