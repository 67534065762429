/*
Basic text styles, defaults and fallbacks.
*/

:root {
    --text-secondary-color: #{$text-secondary-color};

    font-size: $font-size-base;
    line-height: $line-height-base;
}

body,
input,
textarea,
select,
button {
    font-family: $font-family-base;
    color: $text-color;
}

::selection {
    background: $brand-primary;
    color: #fff;
    text-shadow: none;
}
