@import '~@bikemap/scss/settings/settings';

$mobile-nav-width: 16rem;

.main-navigation {
    .main-navigation-link {
        color: $text-color;
        font-weight: bold;
        text-decoration: none;
        display: block;
        padding: 0.5rem 0;
    }

    @media (max-width: $screen-sm-max) {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: -$mobile-nav-width;
        height: 100vh;
        width: $mobile-nav-width;
        transition: all $transition-base ease-out;
        overflow-y: scroll;
        padding: 1rem 0.5rem;
        background-color: #fff;
        border-top: 1px solid $gray-superlight;

        &.open {
            left: 0;
            border-right: 1px solid $gray-superlight;
        }

        .btn {
            width: 100%;
        }

        .new-route-dropdown {
            display: none;
        }

        > .btn,
        > .dropdown {
            margin-bottom: 1rem;
        }
    }

    @media (min-width: $screen-md) {
        position: static;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        > *:not(:first-child) {
            margin-right: 1.5rem;
        }

        .main-navigation-link {
            display: none;
        }
    }
}

@keyframes main_navigation_backdrop_fade_in {
    from { opacity: 0; }
    to { opacity: 1; }
}

.main-navigation-backdrop {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    animation: $transition-base linear main_navigation_backdrop_fade_in forwards;
}
