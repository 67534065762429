@import '~@bikemap/scss/settings/settings';

.cookie-consent {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    transition: transform 0.5s ease-in-out;
    transform: translateY(100%);
    z-index: $top-base;
    width: 100%;
    padding: 1rem;
    pointer-events: none;

    > * {
        pointer-events: all;
    }

    &.visible {
        transform: translateY(0);
    }

    @media (min-width: $screen-md) {
        padding: 2rem;
    }
}
