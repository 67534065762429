/*
A phone image with app screen.
*/
.app-screen {
    width: 17rem;
    position: relative;
    z-index: $simple-top;

    &.lazyloaded,
    &.no-lazyload,
    &.native-lazyload {
        height: auto;
    }

    @media (min-width: $screen-sm) {
        width: 22rem;
    }

    @media (min-width: $screen-md) {
        width: 26rem;
        margin: 0 1rem;
    }
}
