main {
    overflow: hidden;
    margin-top: 0;
}

section {
    background-color: #fff;
}

p + .apps-badges {
    margin-top: 2rem;
}

.lead-section {
    padding-top: 12rem;

    @media (min-width: $screen-sm) {
        padding-top: 8rem;
    }

    @media (min-width: $screen-md) {
        padding-top: 3rem;
        padding-left: 0;

        .section-title,
        .section-lead {
            padding: 0 22rem 0 0;
            text-align: left;
        }
    }
}

.section-illustrated {
    .section-content {
        max-width: 37rem;
        padding: 0;
    }

    .section-illustration {
        margin: 0 -1rem;
        line-height: 0;

        img {
            width: 23rem;
            height: auto;
        }
    }

    @media (max-width: $screen-xs-max) {
        .section-content {
            text-align: center;
        }
    }
}
