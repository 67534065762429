/*
All global definitions for forms and form elements.
*/

// Form elements

$form-element-background-color: #fff;

$form-element-font-family: $font-family-base;

$form-element-font-size: 1rem;

$form-element-color: $text-color;

$form-element-height: 2.4rem;

$form-element-padding: calc(0.4rem - 1px) 0.5rem calc(0.33rem - 1px);

$form-element-border: 1px solid $gray-lighter;

$form-element-border-radius: $border-radius-base;

$form-margin-bottom: 2rem;

$form-element-margin-bottom: 1.6rem;

$label-margin-bottom: 0.3rem;
