@import './wrappers';
@import '../components/apps-badges';
@import '../components/social-links';

.page-footer {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 5rem;
    background-color: $gray-ultralight;

    .footer-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
    }

    .footer-column {
        margin-bottom: 1rem;
    }

    .settings-column,
    .brand-column {
        grid-column: 1 / 3;
    }

    hr {
        margin-top: 1.2rem;
        padding-top: 1rem;
    }

    .footer-nav-list {
        list-style: none;
        margin-bottom: 2rem;

        li {
            margin-bottom: 0.3rem;
        }

        a {
            color: $text-color;

            &:hover,
            &:focus {
                color: $link-color;
                text-decoration: underline;
            }
        }
    }

    .h5 {
        margin-top: 0;
    }

    .home-link {
        display: inline-block;

        svg {
            width: 9rem;
            height: 2.8rem;
        }
    }

    @media (min-width: $screen-sm) {
        .footer-content {
            grid-template-columns: 40% 1fr 1fr;
            grid-column-gap: 3rem;
        }

        .footer-column {
            grid-column: unset;
        }
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        .footer-column.brand-column {
            grid-column: 1/4;
        }

        .apps-badges {
            display: inline-block;
            margin-right: 1rem;
            vertical-align: text-top;
        }
    }

    @media (min-width: $screen-md) {
        .footer-content {
            grid-template-columns: 20rem 1fr 1fr 30%;
            grid-column-gap: 3rem;
        }
    }
}
