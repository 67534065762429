@import './colors';

/*
Additional appearance attributes that don't affect the shape, e.g. for different states.
*/

$box-shadow-low: 0 0.06rem 0.4rem rgb(0 0 0 / 25%);
$box-shadow-base: 0 0.3rem 0.6rem rgb(0 0 0 / 30%), 0 1.1rem 2rem rgb(0 0 0 / 15%);
$box-shadow-high: 0 0.4rem 1.8rem rgb(0 0 0 / 10%), 0 1.5rem 2.2rem rgb(0 0 0 / 8%);
$box-shadow-premium-button: 0 15px 31px 0 $premium-shadow-1, 0 3px 18px 0 $premium-shadow-2;
