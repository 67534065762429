@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/mixins/visibility';

/*
A styled pseudo dropdown select field compatible with forms.
*/
.select-dropdown {
    > input {
        @include visually-hidden;
    }
}
