/*
Position the element absolute over the whole relative parent.
*/
.overlay,
%overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
Display a ::before pseudo element all over this element.
*/
.overlay-before,
%overlay-before {
    position: relative;

    &::before {
        @extend %overlay;

        content: '';
    }
}

/*
Display a ::after pseudo element all over this element.
*/
.overlay-after,
%overlay-after {
    position: relative;

    &::after {
        @extend %overlay;

        content: '';
    }
}

/*
Display a ::after pseudo element over the whole relative parent to make the entire box clickable.
*/
.overlay-btn,
.btn.overlay-btn,
%overlay-btn {
    position: static;

    &::after {
        @extend %overlay;

        content: '';
    }
}
