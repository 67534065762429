@charset "UTF-8";
@import '../settings/z-index';
@import '~@bikemap/scss/mixins/visibility';

/* = Loading spinner
===================================================== */

.loading-spinner {
    @include loading-spinner;

    &.btn-ghost,
    .btn-ghost {
        color: transparent;
    }

    &.spinner-blue {
        &:not(:required)::before {
            border-color: transparent transparent $brand-primary $brand-primary;
        }
    }
}

// How to use spinner-overlay:
// - place an empty div inside the element that should receive the overlay
// - give the div the classes spinner-overlay and loading-spinner
// - the parent element must be position: relative
// - use show() and hide() to toggle the spinner
.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-spinner;
    background-color: rgb(255 255 255 / 65%);
    display: none;
}
