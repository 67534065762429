%wrapper {
    padding: 0 1rem;
    margin: 0 auto;

    @media (min-width: $screen-md) {
        padding: 0 (2 * $base-padding-horizontal);
    }
}

.wrapper-full {
    @extend %wrapper;

    width: 100%;
    max-width: $max-width-content;
}

.wrapper-center {
    @extend %wrapper;

    width: fit-content;
    max-width: 100%;

    @media (min-width: $max-width-content) {
        max-width: $max-width-content;
    }
}

.wrapper-text,
%wrapper-text {
    @extend %wrapper;

    width: 100%;
    max-width: $max-width-readable;
}

.wrapper-block-form {
    @extend %wrapper;

    width: 100%;
    max-width: $max-width-block-form;
}

.wrapper-scroll-xs {
    @media (max-width: $screen-xs-max) {
        overflow-x: auto;

        > * {
            width: fit-content;
        }
    }
}
