@import '~@bikemap/scss/mixins/colors';
@import '~@bikemap/scss/settings/colors';

.social-link {
    @include content-white();

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-dimmed;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    line-height: 2em;
    text-align: center;
    font-size: 1.5em;

    span {
        font-weight: bold;
        font-size: 0.6em;
    }

    &:hover,
    &:focus {
        background-color: lighten($gray-light, 10%);
        text-decoration: none;
    }

    [class^='i-'] {
        display: inline-block;
        font-size: 1.3em;
    }
}
