/*
Text related utility classes.
*/

.text-normal {
    font-weight: normal;
}

.text-bold {
    font-weight: bold;
}

.text-underlined {
    text-decoration: underline;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.inline-premium-badges {
    .badged-premium {
        white-space: nowrap;
    }

    .premium-badges-shadow {
        width: 2.5em;
        height: 2.2em;
        margin: -0.3em -0.4em -1em -0.2em;
    }

    .premium-badge-shadow {
        width: 1.5em;
        height: 1.7em;
        margin: -0.2em -0.2em -0.6em;
    }
}
