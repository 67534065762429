/*
Common attributes of element shapes.
*/

// Sizes
$header-height: 3.2rem;

$max-width-content: 90rem; // Maximum width of normal page content
$max-width-readable: 60rem; // Maximum width of a readable text column
$max-width-block-form: 32rem; // Maximum width of a container for full-width form elements

// Spacing
$base-padding-horizontal: 2rem;

// Border radius
$border-radius-base: 0.2rem; // 3px
$border-radius-large: 0.4rem; // 6px
$border-radius-small: 2px;

// Tabs
$active-tab-border-height: 0.2rem;

// Tiles
$tile-border-radius: $border-radius-large;
$tile-media-padding: 0.5rem;
$tile-max-width: 25rem;
