@charset "UTF-8";

/*
Conditionally show or hide content by user authentication / premium status.
Show classes can't be combined! Just use hide classes instead.
*/

// hide all conditional content while unsure
body:not(.bm-login):not(.bm-guest) {
    .bm-login-hide,
    .bm-login-show,
    .bm-premium-show,
    .bm-premium-hide,
    .bm-premium-monthly-show,
    .bm-premium-monthly-hide,
    .bm-premium-quarterly-show,
    .bm-premium-quarterly-hide,
    .bm-premium-yearly-show,
    .bm-premium-yearly-hide,
    .bm-premium-3-years-show,
    .bm-premium-3-years-hide,
    .bm-premium-lifetime-show,
    .bm-premium-lifetime-hide,
    .bm-referral-premium-show,
    .bm-referral-premium-hide,
    .bm-premium-free-show,
    .bm-premium-free-hide {
        &:not(.bm-important) { // important SEO content
            display: none !important;
        }
    }
}

body.bm-guest {
    .bm-login-show {
        display: none !important;
    }
}

body.bm-login {
    .bm-login-hide {
        display: none !important;
    }
}

body:not(.bm-premium) {
    .bm-premium-show {
        display: none !important;
    }
}

body.bm-premium {
    .bm-premium-hide {
        display: none !important;
    }
}

body:not(.bm-referral-premium) {
    .bm-referral-premium-show {
        display: none !important;
    }
}

body.bm-referral-premium {
    .bm-referral-premium-hide {
        display: none !important;
    }
}

@each $plan in monthly quarterly yearly 3-years lifetime free {
    body:not(.bm-premium-#{$plan}) {
        .bm-premium-#{$plan}-show {
            display: none !important;
        }
    }
    body.bm-premium-#{$plan} {
        *.bm-premium-#{$plan}-hide {
            display: none !important;
        }
    }
}
