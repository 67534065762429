.premium-section {
    padding: 0 0 8rem;

    .premium-badge {
        width: 5rem;
        height: 6rem;
        margin-bottom: 0.5rem;
    }

    .section-illustration .app-screen {
        width: 26rem;
    }

    &.right-aligned .section-illustration + .section-content {
        margin-left: 5rem;

        @media (min-width: $screen-lg) {
            margin-left: 7rem;
        }

        @media (max-width: $screen-sm-max) {
            margin-left: 0;
        }
    }

    @media (max-width: $screen-sm-max) {
        .section-illustration {
            display: none;
        }
    }

    @media (min-width: $screen-md) {
        padding-bottom: 10rem;
    }

    .gift {
        position: absolute;
        z-index: $simple-top;

        &.gift-left {
            left: 1rem;
            bottom: 40%;
            width: 6rem;
            transform: rotate(-20deg);
        }

        &.gift-right {
            right: 0;
            bottom: 20%;
            width: 11rem;
            transform: rotate(20deg);
        }
    }
}
