@keyframes loading-activity {
    from {
        background-position-x: -100%;
    }

    to {
        background-position-x: 200%;
    }
}

/*
Adds animated indication that an element is a placeholder for something currently loading.
*/
.loading-placeholder,
%loading-placeholder {
    background-color: $gray-ultralight;
    background-image: linear-gradient(to right, rgb(255 255 255 / 0%), rgb(255 255 255 / 50%), rgb(255 255 255 / 0%));
    background-repeat: no-repeat;
    animation: loading-activity 2s linear infinite;
    background-size: 50% 100%;
}
