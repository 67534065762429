@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/mixins/visibility';

/*
A styled pseudo dropdown select field compatible with forms.
*/
.dropdown {
    position: relative;

    .dropdown-list {
        z-index: $content-base;
        top: 100%;
    }

    &.dropdown-right {
        .dropdown-list {
            right: 0;
            left: auto;
        }
    }
}
