/*
Breadcrumbs section above footer in global layout.
*/
.section-breadcrumbs {
    background-color: #fff;
    border-top: 1px solid $gray-superlight;
    border-bottom: 1px solid $gray-superlight;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
