$highlight: lighten($highlight-primary, 3%);
$normal: $brand-primary;

#icon-gradient {
    // If styling stop color via CSS is not supported
    --highlight: #{$highlight};
    --normal: #{$normal};

    // If CSS variables are not supported
    .stop-highlight {
        stop-color: $highlight;
    }

    .stop-normal {
        stop-color: $normal;
    }
}
