@import '~@bikemap/scss/settings/settings';
@import '~@bikemap/scss/utils/loading-placeholder';

/*
Displays the user's avatar, name and one additional info.
*/
.user {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;

    // Override styles for <a>
    &:hover,
    &:focus {
        text-decoration: none;
    }

    .avatar {
        flex-shrink: 0;
        margin-right: 0.5em;
    }

    &.prominent,
    &.vertical {
        .avatar {
            font-size: 2em;
        }
    }

    &:not(.vertical) {
        .user-info {
            margin-bottom: -0.1em;
        }
    }

    &.vertical {
        flex-direction: column;
        max-width: 100%;

        .avatar {
            margin: 0 0 0.2em;
        }

        .user-info {
            text-align: center;
            white-space: normal;
            max-width: 100%;
            line-height: $line-height-tight;
        }

        .user-name {
            max-height: 3.6em;
        }
    }
}

a.user,
button.user {
    border: 0;
    background: none;
    cursor: pointer;
    font-size: 1em;

    &:hover,
    &:focus {
        .user-name {
            text-decoration: underline;
        }
    }
}

.user-placeholder {
    display: flex;
    align-items: center;

    &::before,
    &::after {
        @extend %loading-placeholder;

        content: '';
        display: block;
    }

    &::before {
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        margin-right: 0.5em;
    }

    &::after {
        width: 10em;
        height: 1.5em;
    }
}
