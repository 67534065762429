/*
Consistent z-index levels across the site.
*/
$simple-below: -1;
$simple-base: 0;
$simple-top: 1;
$content-base: 20;
$header-base: 42;
$top-base: 40;

$z-index-notifications: $header-base - 10;
$z-index-page-header: $header-base;
$z-index-modal-backdrop: $header-base + 1;
$z-index-modal: $header-base + 10;
$z-index-tutorial: $header-base + 20;
$z-index-notifications-top: $header-base + 30;

$z-index-flyout-banner-close: 10;
$z-index-multi-search-dropdown: -1;
$z-index-tile-footer-small: 10;
$z-index-tile-overlay: 10;
$z-index-premium-comparison-highlight: -1;
$z-index-user-inspiration-illustration: 0;
$z-index-top-users-list-content: 11;
$z-index-route-detail-preview-image: 2;
$z-index-route-detail-info-container: 2;
$z-index-map-controls: 2;
$z-index-map-style-controls: 0;
$z-index-mapwrapper-small: -1;
$z-index-no-waypoints-message: 50;
$z-index-location-search-container: 2;
$z-index-map-search-result-highlight: 2;
$z-index-map-controls-container: 3;
$z-index-spinner: 10;
$z-index-foreground: 12;
$z-index-link-full-div: 10;
$z-index-loading-background: 10;
$z-index-cover-photo: 10;
$z-index-avatar-confirm: 0;
$z-index-avatar-remove: 0;
$z-index-avatar-hover-confirm: 10;
$z-index-cover-confirm: 0;
$z-index-cover-remove: 0;
$z-index-cover-hover-confirm: 10;
$form-field-validation-list: 21;

// dropzone z-indices
$z-index-dropzone-preview: 1000;
$z-index-dropzone-remove-route: 0;
$z-index-dropzone-details: 20;
$z-index-dropzone-image: 10;
$z-index-dropzone-mark: 500;
$z-index-dropzone-progress: 1000;
$z-index-dropzone-error: 1000;
$z-index-dropzone-confirm: 0;
$z-index-dropzone-remove: 0;
$z-index-dropzone-hover-confirm: 10;
