@charset "UTF-8";

/* = Flag object
===================================================== */

/**
 * Inspired by (read: mostly stolen from) - http://csswizardry.com/2013/05/the-flag-object/
 */
.flag {
    display: table;
    width: 100%;
}

.flag-inline {
    display: flex;
    align-items: center;
}

.flag-img {
    padding-right: 1rem;

    img {
        display: block;
        max-width: none;
    }

    .flag-rev & {
        padding-right: 0;
        padding-left: 1rem;
    }

    &.flag-img-inline {
        display: inline-block;
    }
}

.flag-body {
    width: 100%;

    &.flag-body-inline {
        display: inline;
        text-align: left;
        padding-left: 1rem;
    }
}

.flag-row {
    text-align: center;
    color: $text-secondary-color;
}
