/*
Global base styles needed everywhere.
*/

@import './settings/settings';

@import './icons/icon';

// postcss-normalize
@import-normalize;

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*:focus { outline: 0; }

:root {
    // Overwrite inlined CSS (which prevents FOUC)
    display: block;
    min-height: 100%;
    scroll-behavior: smooth;

    &.noscroll {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
    }
}

body {
    background-color: $body-bg;

    &.scrolling-disabled {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
    }
}

.page-header-container {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-index-page-header;
}

#native-app-banner {
    @media (min-width: $screen-sm) {
        display: none;
    }
}

// Page main content
main {
    background-color: lighten($body-bg, 3%);
    position: relative;
}

img {
    display: block;
    max-width: 100%;
    height: auto;

    &::selection {
        background: transparent;
    }
}

/* fix anchor tag links due to fixed header */
:target::before {
    content: '';
    display: block;
    height: $header-height + 2rem; /* fixed header height */
    margin: -($header-height + 2rem) 0 0; /* negative fixed header height */
}

hr {
    border: 0;
    border-top: 1px solid #ddd;
    border-color: rgb(0 0 0 / 10%);
    margin: 1em 0 0;
    padding: 1em 0 0;
}

@import './typography/global';

@import './utils/backgrounds';
@import './utils/visibility';
@import './utils/loading-spinner';
@import './utils/overlays';
@import 'utils/conditional-content';
@import 'utils/tooltip';
@import 'utils/spacing';
