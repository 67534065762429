@import '../settings/settings';
@import '../mixins/form';

form {
    margin-bottom: $form-margin-bottom;
}

label,
legend {
    display: block;
    font-weight: bold;
    font-size: $form-element-font-size;
    color: $text-color;

    [class^='i-']:first-child {
        margin-right: 0.2em;
    }
}

fieldset {
    border: 0;
}

input,
select,
textarea {
    @include form-element-basics;

    box-shadow: none; // Firefox adds red box shadow on empty required fields after defocus

    &::placeholder {
        color: $text-secondary-color;
    }
}

input[type='radio'] {
    width: auto;
    display: inline-block;
}

select {
    appearance: none;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.3rem);
    padding-right: 2rem;
    height: calc(#{(1rem * $line-height-base + 0.9rem)} - 2px);
}

textarea {
    resize: none;
}
