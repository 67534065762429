@import 'settings/settings';

.carousel {
    display: flex;
    justify-content: center;

    &.animated {
        width: 100vw;
        margin-left: 100vw;

        .carousel-item {
            position: absolute;

            &.cloned {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .carousel-item {
        list-style: none;
        font-weight: bold;
        font-size: 1.3rem;
        background-color: $gray-superlight;
        border-radius: 5rem;
        padding: 1rem 1.5rem;
        padding-left: 1.1rem;
        margin: 0 13px;
        position: static;
        width: 200px;
        max-width: 200px;
        display: inline-flex;
        align-items: center;

        &.cloned {
            display: none;
        }

        .icon {
            display: inline-block;
            position: relative;
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            margin-right: 10px;
            overflow: hidden;

            img,
            svg {
                height: 2.5rem;
                width: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
