$icomoon-font-family: "bikemap-icons" !default;
$icomoon-font-path: "fonts" !default;

$i-elevation-circle: "\e90b";
$i-speedometer-circle: "\e921";
$i-distance-circle: "\e925";
$i-duration: "\e928";
$i-descent-circle: "\e95f";
$i-ascent-circle: "\e969";
$i-premium-lock: "\e901";
$i-copy: "\e900";
$i-helmet: "\e905";
$i-admin: "\e906";
$i-apple: "\e907";
$i-arrow-down: "\e908";
$i-arrow-left: "\e909";
$i-arrow-up: "\e90a";
$i-ascent: "\e90c";
$i-bike: "\e90d";
$i-calendar: "\e90e";
$i-cancel: "\e90f";
$i-caret-down: "\e910";
$i-caret-left: "\e911";
$i-caret-right: "\e912";
$i-caret-up: "\e913";
$i-check: "\e914";
$i-checkbox-checked: "\e915";
$i-checkbox-outline: "\e916";
$i-chevron-right: "\e918";
$i-close: "\e919";
$i-code: "\e91a";
$i-collection: "\e91b";
$i-comment: "\e91c";
$i-compass: "\e91d";
$i-create: "\e91e";
$i-dashboard: "\e91f";
$i-delete: "\e920";
$i-descent: "\e922";
$i-devicesync: "\e923";
$i-directions: "\e924";
$i-distance: "\e926";
$i-download: "\e927";
$i-email: "\e929";
$i-eraser: "\e92a";
$i-expand: "\e92b";
$i-facebook: "\e92c";
$i-feedback: "\e92d";
$i-filter: "\e92f";
$i-google: "\e930";
$i-gpx-add: "\e931";
$i-gravel: "\e932";
$i-heart-empty: "\e933";
$i-heart: "\e934";
$i-help: "\e935";
$i-home: "\e936";
$i-idea: "\e937";
$i-important: "\e938";
$i-info: "\e939";
$i-instagram: "\e93a";
$i-invisible: "\e93b";
$i-layers: "\e93c";
$i-link: "\e93d";
$i-location-edit: "\e93e";
$i-location: "\e93f";
$i-logout: "\e940";
$i-magnet: "\e941";
$i-map-search: "\e942";
$i-map: "\e943";
$i-menu-close: "\e944";
$i-menu: "\e945";
$i-merge: "\e946";
$i-mountainbike: "\e947";
$i-paved: "\e94a";
$i-pen: "\e94b";
$i-people: "\e94c";
$i-phone-download: "\e94d";
$i-photo-add: "\e94e";
$i-photo: "\e94f";
$i-poi-add: "\e950";
$i-premium-badge: "\e951";
$i-print: "\e952";
$i-redo: "\e953";
$i-refresh: "\e954";
$i-return: "\e955";
$i-reverse: "\e956";
$i-roadbike: "\e957";
$i-roundtrip: "\e958";
$i-route: "\e959";
$i-routes: "\e95a";
$i-rss: "\e95b";
$i-search: "\e95c";
$i-settings: "\e95d";
$i-share: "\e95e";
$i-star-empty: "\e960";
$i-star-half: "\e961";
$i-star: "\e962";
$i-sync: "\e964";
$i-thumb-down: "\e965";
$i-thumb-up: "\e966";
$i-time: "\e967";
$i-timeline: "\e968";
$i-twitter: "\e96a";
$i-undo: "\e96b";
$i-unpaved: "\e96c";
$i-upload: "\e96d";
$i-user-add: "\e96e";
$i-view-grid: "\e96f";
$i-view-list: "\e970";
$i-visible: "\e971";
$i-warning-sign: "\e972";
$i-youtube: "\e973";
$i-pois-hazard: "\e974";
$i-pois-police: "\e975";
$i-pois-general: "\e976";
$i-pois-blocking-object: "\e977";
$i-poi-hospital: "\e978";
$i-poi-attraction: "\e979";
$i-poi-bikeshop: "\e97a";
$i-poi-toilet: "\e97b";
$i-poi-bike-rental: "\e97c";
$i-poi-osm-service: "\e97d";
$i-poi-atm: "\e97e";
$i-poi-sobriety-check: "\e97f";
$i-poi-equipment-check: "\e980";
$i-poi-traffic-violation: "\e981";
$i-poi-splinters: "\e982";
$i-poi-traffic: "\e983";
$i-poi-water: "\e984";
$i-poi-shelter: "\e985";
$i-poi-bike-parking: "\e986";
$i-poi-e-bike-charging: "\e987";
$i-poi-pump: "\e988";
$i-poi-service: "\e989";
$i-poi-barrier: "\e98a";
$i-poi-construction: "\e98b";
$i-poi-blocking-object: "\e98c";
$i-poi-gravel: "\e98d";
$i-poi-pothole: "\e98e";
$i-poi-cobblestones: "\e98f";
$i-poi-slippery-road: "\e990";
$i-add: "\e902";
$i-edit: "\e903";
$i-image: "\e904";

