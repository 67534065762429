@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fxtd5j') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fxtd5j') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fxtd5j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-elevation-circle {
  &:before {
    content: $i-elevation-circle; 
  }
}
.i-speedometer-circle {
  &:before {
    content: $i-speedometer-circle; 
  }
}
.i-distance-circle {
  &:before {
    content: $i-distance-circle; 
  }
}
.i-duration {
  &:before {
    content: $i-duration; 
  }
}
.i-descent-circle {
  &:before {
    content: $i-descent-circle; 
  }
}
.i-ascent-circle {
  &:before {
    content: $i-ascent-circle; 
  }
}
.i-premium-lock {
  &:before {
    content: $i-premium-lock; 
  }
}
.i-copy {
  &:before {
    content: $i-copy; 
  }
}
.i-helmet {
  &:before {
    content: $i-helmet; 
  }
}
.i-admin {
  &:before {
    content: $i-admin; 
  }
}
.i-apple {
  &:before {
    content: $i-apple; 
  }
}
.i-arrow-down {
  &:before {
    content: $i-arrow-down; 
  }
}
.i-arrow-left {
  &:before {
    content: $i-arrow-left; 
  }
}
.i-arrow-up {
  &:before {
    content: $i-arrow-up; 
  }
}
.i-ascent {
  &:before {
    content: $i-ascent; 
  }
}
.i-bike {
  &:before {
    content: $i-bike; 
  }
}
.i-calendar {
  &:before {
    content: $i-calendar; 
  }
}
.i-cancel {
  &:before {
    content: $i-cancel; 
  }
}
.i-caret-down {
  &:before {
    content: $i-caret-down; 
  }
}
.i-caret-left {
  &:before {
    content: $i-caret-left; 
  }
}
.i-caret-right {
  &:before {
    content: $i-caret-right; 
  }
}
.i-caret-up {
  &:before {
    content: $i-caret-up; 
  }
}
.i-check {
  &:before {
    content: $i-check; 
  }
}
.i-checkbox-checked {
  &:before {
    content: $i-checkbox-checked; 
  }
}
.i-checkbox-outline {
  &:before {
    content: $i-checkbox-outline; 
  }
}
.i-chevron-right {
  &:before {
    content: $i-chevron-right; 
  }
}
.i-close {
  &:before {
    content: $i-close; 
  }
}
.i-code {
  &:before {
    content: $i-code; 
  }
}
.i-collection {
  &:before {
    content: $i-collection; 
  }
}
.i-comment {
  &:before {
    content: $i-comment; 
  }
}
.i-compass {
  &:before {
    content: $i-compass; 
  }
}
.i-create {
  &:before {
    content: $i-create; 
  }
}
.i-dashboard {
  &:before {
    content: $i-dashboard; 
  }
}
.i-delete {
  &:before {
    content: $i-delete; 
  }
}
.i-descent {
  &:before {
    content: $i-descent; 
  }
}
.i-devicesync {
  &:before {
    content: $i-devicesync; 
  }
}
.i-directions {
  &:before {
    content: $i-directions; 
  }
}
.i-distance {
  &:before {
    content: $i-distance; 
  }
}
.i-download {
  &:before {
    content: $i-download; 
  }
}
.i-email {
  &:before {
    content: $i-email; 
  }
}
.i-eraser {
  &:before {
    content: $i-eraser; 
  }
}
.i-expand {
  &:before {
    content: $i-expand; 
  }
}
.i-facebook {
  &:before {
    content: $i-facebook; 
  }
}
.i-feedback {
  &:before {
    content: $i-feedback; 
  }
}
.i-filter {
  &:before {
    content: $i-filter; 
  }
}
.i-google {
  &:before {
    content: $i-google; 
  }
}
.i-gpx-add {
  &:before {
    content: $i-gpx-add; 
  }
}
.i-gravel {
  &:before {
    content: $i-gravel; 
  }
}
.i-heart-empty {
  &:before {
    content: $i-heart-empty; 
  }
}
.i-heart {
  &:before {
    content: $i-heart; 
  }
}
.i-help {
  &:before {
    content: $i-help; 
  }
}
.i-home {
  &:before {
    content: $i-home; 
  }
}
.i-idea {
  &:before {
    content: $i-idea; 
  }
}
.i-important {
  &:before {
    content: $i-important; 
  }
}
.i-info {
  &:before {
    content: $i-info; 
  }
}
.i-instagram {
  &:before {
    content: $i-instagram; 
  }
}
.i-invisible {
  &:before {
    content: $i-invisible; 
  }
}
.i-layers {
  &:before {
    content: $i-layers; 
  }
}
.i-link {
  &:before {
    content: $i-link; 
  }
}
.i-location-edit {
  &:before {
    content: $i-location-edit; 
  }
}
.i-location {
  &:before {
    content: $i-location; 
  }
}
.i-logout {
  &:before {
    content: $i-logout; 
  }
}
.i-magnet {
  &:before {
    content: $i-magnet; 
  }
}
.i-map-search {
  &:before {
    content: $i-map-search; 
  }
}
.i-map {
  &:before {
    content: $i-map; 
  }
}
.i-menu-close {
  &:before {
    content: $i-menu-close; 
  }
}
.i-menu {
  &:before {
    content: $i-menu; 
  }
}
.i-merge {
  &:before {
    content: $i-merge; 
  }
}
.i-mountainbike {
  &:before {
    content: $i-mountainbike; 
  }
}
.i-paved {
  &:before {
    content: $i-paved; 
  }
}
.i-pen {
  &:before {
    content: $i-pen; 
  }
}
.i-people {
  &:before {
    content: $i-people; 
  }
}
.i-phone-download {
  &:before {
    content: $i-phone-download; 
  }
}
.i-photo-add {
  &:before {
    content: $i-photo-add; 
  }
}
.i-photo {
  &:before {
    content: $i-photo; 
  }
}
.i-poi-add {
  &:before {
    content: $i-poi-add; 
  }
}
.i-premium-badge {
  &:before {
    content: $i-premium-badge; 
  }
}
.i-print {
  &:before {
    content: $i-print; 
  }
}
.i-redo {
  &:before {
    content: $i-redo; 
  }
}
.i-refresh {
  &:before {
    content: $i-refresh; 
  }
}
.i-return {
  &:before {
    content: $i-return; 
  }
}
.i-reverse {
  &:before {
    content: $i-reverse; 
  }
}
.i-roadbike {
  &:before {
    content: $i-roadbike; 
  }
}
.i-roundtrip {
  &:before {
    content: $i-roundtrip; 
  }
}
.i-route {
  &:before {
    content: $i-route; 
  }
}
.i-routes {
  &:before {
    content: $i-routes; 
  }
}
.i-rss {
  &:before {
    content: $i-rss; 
  }
}
.i-search {
  &:before {
    content: $i-search; 
  }
}
.i-settings {
  &:before {
    content: $i-settings; 
  }
}
.i-share {
  &:before {
    content: $i-share; 
  }
}
.i-star-empty {
  &:before {
    content: $i-star-empty; 
  }
}
.i-star-half {
  &:before {
    content: $i-star-half; 
  }
}
.i-star {
  &:before {
    content: $i-star; 
  }
}
.i-sync {
  &:before {
    content: $i-sync; 
  }
}
.i-thumb-down {
  &:before {
    content: $i-thumb-down; 
  }
}
.i-thumb-up {
  &:before {
    content: $i-thumb-up; 
  }
}
.i-time {
  &:before {
    content: $i-time; 
  }
}
.i-timeline {
  &:before {
    content: $i-timeline; 
  }
}
.i-twitter {
  &:before {
    content: $i-twitter; 
  }
}
.i-undo {
  &:before {
    content: $i-undo; 
  }
}
.i-unpaved {
  &:before {
    content: $i-unpaved; 
  }
}
.i-upload {
  &:before {
    content: $i-upload; 
  }
}
.i-user-add {
  &:before {
    content: $i-user-add; 
  }
}
.i-view-grid {
  &:before {
    content: $i-view-grid; 
  }
}
.i-view-list {
  &:before {
    content: $i-view-list; 
  }
}
.i-visible {
  &:before {
    content: $i-visible; 
  }
}
.i-warning-sign {
  &:before {
    content: $i-warning-sign; 
  }
}
.i-youtube {
  &:before {
    content: $i-youtube; 
  }
}
.i-pois-hazard {
  &:before {
    content: $i-pois-hazard; 
  }
}
.i-pois-police {
  &:before {
    content: $i-pois-police; 
  }
}
.i-pois-general {
  &:before {
    content: $i-pois-general; 
  }
}
.i-pois-blocking-object {
  &:before {
    content: $i-pois-blocking-object; 
  }
}
.i-poi-hospital {
  &:before {
    content: $i-poi-hospital; 
  }
}
.i-poi-attraction {
  &:before {
    content: $i-poi-attraction; 
  }
}
.i-poi-bikeshop {
  &:before {
    content: $i-poi-bikeshop; 
  }
}
.i-poi-toilet {
  &:before {
    content: $i-poi-toilet; 
  }
}
.i-poi-bike-rental {
  &:before {
    content: $i-poi-bike-rental; 
  }
}
.i-poi-osm-service {
  &:before {
    content: $i-poi-osm-service; 
  }
}
.i-poi-atm {
  &:before {
    content: $i-poi-atm; 
  }
}
.i-poi-sobriety-check {
  &:before {
    content: $i-poi-sobriety-check; 
  }
}
.i-poi-equipment-check {
  &:before {
    content: $i-poi-equipment-check; 
  }
}
.i-poi-traffic-violation {
  &:before {
    content: $i-poi-traffic-violation; 
  }
}
.i-poi-splinters {
  &:before {
    content: $i-poi-splinters; 
  }
}
.i-poi-traffic {
  &:before {
    content: $i-poi-traffic; 
  }
}
.i-poi-water {
  &:before {
    content: $i-poi-water; 
  }
}
.i-poi-shelter {
  &:before {
    content: $i-poi-shelter; 
  }
}
.i-poi-bike-parking {
  &:before {
    content: $i-poi-bike-parking; 
  }
}
.i-poi-e-bike-charging {
  &:before {
    content: $i-poi-e-bike-charging; 
  }
}
.i-poi-pump {
  &:before {
    content: $i-poi-pump; 
  }
}
.i-poi-service {
  &:before {
    content: $i-poi-service; 
  }
}
.i-poi-barrier {
  &:before {
    content: $i-poi-barrier; 
  }
}
.i-poi-construction {
  &:before {
    content: $i-poi-construction; 
  }
}
.i-poi-blocking-object {
  &:before {
    content: $i-poi-blocking-object; 
  }
}
.i-poi-gravel {
  &:before {
    content: $i-poi-gravel; 
  }
}
.i-poi-pothole {
  &:before {
    content: $i-poi-pothole; 
  }
}
.i-poi-cobblestones {
  &:before {
    content: $i-poi-cobblestones; 
  }
}
.i-poi-slippery-road {
  &:before {
    content: $i-poi-slippery-road; 
  }
}
.i-add {
  &:before {
    content: $i-add; 
  }
}
.i-edit {
  &:before {
    content: $i-edit; 
  }
}
.i-image {
  &:before {
    content: $i-image; 
  }
}

