@import '~@bikemap/scss/settings/settings';

.breadcrumbs {
    margin: 0;
    white-space: nowrap;

    li {
        display: inline-block;

        a:not(:hover) {
            color: $text-color;
        }
    }

    .active {
        font-weight: 500;
    }

    .i-chevron-right {
        margin-top: -2px;
    }

    .i-home {
        margin-top: -4px;
    }
}
