@charset "UTF-8";

@import 'close-button';
@import '~@bikemap/scss/settings/colors';

$bg-opacity: 0.95;
$notification-padding: 1rem;

#notifications-container {
    position: fixed;
    top: ($header-height + 1rem);
    right: 15px;
    z-index: $z-index-notifications;
    width: calc(100% - 30px);
    max-width: 35rem;

    body.modal-open & {
        z-index: $z-index-notifications-top;
    }
}

@keyframes notification-fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes notification-fade-out {
    from {
        opacity: 1;
        max-height: none;
        padding: $notification-padding;
    }

    50% {
        opacity: 0;
        max-height: 15rem;
        padding: $notification-padding;
    }

    to {
        max-height: 0;
        padding: 0;
    }
}

.notification {
    display: flex;
    position: relative;
    background: rgba(#fff, $bg-opacity);
    padding: $notification-padding;
    box-shadow: 0 3px 15px rgba(#000, 0.3);
    border-radius: $border-radius-base;
    animation: 0.5s linear notification-fade-in;

    &.fade-out {
        animation: 0.5s linear notification-fade-out;
        opacity: 0;
        max-height: 0;
        padding: 0;
        overflow: hidden;
    }

    .notification-image-content {
        position: relative;
        align-self: center;
        flex: 0 0 auto;
        margin-right: 1rem;

        .notification-image-wrapper {
            width: 5.867rem;
            height: 5.867rem;
            border-radius: 100%;
            border: 4px solid #fff;
            overflow: hidden;

            img {
                max-width: calc(100% + 2px);
                margin: -1px;
            }
        }

        .notification-image-badge {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 2.133rem;
            max-height: 2.133rem;
            height: 100%;
            width: auto;
        }
    }

    .notification-message-content {
        flex: 1 1 auto;
    }

    .notification-title {
        margin-top: 0.2rem;
        margin-right: 3rem;

        &:last-child {
            margin-bottom: 0;
        }

        .notification-icon {
            display: inline-block;
            max-width: 2rem;
            max-height: 2rem;
            margin-right: 0.5rem;
        }

        svg.notification-icon {
            vertical-align: -0.6rem;
        }
    }

    p {
        font-size: 1rem;
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    .notification-footer {
        display: flex;
        justify-content: space-between;
        line-height: normal;

        .notification-footer-left {
            opacity: 0.6;
            padding-right: 0.3rem;
        }

        .notification-footer-right {
            display: flex;
            align-items: center;
            padding-left: 0.3rem;
        }

        .dot {
            display: inline-block;
            position: relative;
            border-radius: 100%;
            border: 1px solid #efeff4;
            background: linear-gradient(219deg, $highlight-primary 14.45%, $brand-primary 85.55%);
            width: 0.867rem;
            height: 0.867rem;
            margin: -1px 0 0 0.3rem;

            &::after {
                content: '';
                position: absolute;
                background: #fff;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                width: 0.2rem;
                height: 0.2rem;
                margin: -0.1rem 0 0 -0.1rem;
            }
        }
    }

    + .notification {
        margin-top: 0.5rem;
    }

    &.primary,
    &.premium,
    &.success,
    &.warning,
    &.danger {
        &,
        p,
        a {
            color: #fff;
        }

        a {
            font-weight: bold;
        }

        .btn.close-btn {
            opacity: 1;
        }

        [class^='i-'] {
            color: #fff;
        }
    }

    &.primary {
        background:
            linear-gradient(
                219deg,
                rgba($highlight-primary, $bg-opacity) 14.45%,
                rgba($brand-primary, $bg-opacity) 85.55%
            );
    }

    &.premium {
        background:
            linear-gradient(
                219deg,
                rgba($highlight-premium, $bg-opacity) 14.45%,
                rgba($brand-premium, $bg-opacity) 85.55%
            );
    }

    &.success {
        background-color: rgba($brand-success, $bg-opacity);
    }

    &.warning {
        background-color: rgba($brand-warning, $bg-opacity);
    }

    &.danger {
        background-color: rgba($brand-danger, $bg-opacity);
    }

    > .btn:last-child {
        margin-bottom: 0.5rem;
    }
}

.btn-group-row { // deprecated, still used in a notification
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > .btn {
        margin-right: 10px;

        @media (max-width: $screen-xs) {
            width: 100%;
            margin-right: 0;
            margin-top: 10px;
        }
    }
}
