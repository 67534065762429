@import '~@bikemap/scss/settings/settings';

/*
Mixins for forms and form elements.
*/

// Basic styles for most form elements
@mixin form-element-basics {
    font-size: $form-element-font-size;
    background-color: $form-element-background-color;
    outline: 0;
    padding: $form-element-padding;
    line-height: $line-height-base;
    border: $form-element-border;
    border-radius: $form-element-border-radius;
    color: $form-element-color;
    font-family: $form-element-font-family;
    display: block;
    width: 100%;

    &.error {
        border-color: $brand-danger;
    }

    &:focus,
    &.error:focus {
        border-color: $brand-primary;
    }

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        color: $text-secondary-color;
        cursor: default;
        opacity: 0.75;
    }

    &:not([type='checkbox']):not([type='radio']) {
        min-height: $form-element-height;
    }
}

@mixin form-separator {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-secondary-color);
    margin: 2rem 0;
    line-height: 1;
    font-size: 1rem;

    &::before,
    &::after {
        content: '';
        border-bottom: 1px solid $gray-lighter;
        position: absolute;
        top: 0.4rem;
    }

    &::before {
        right: calc(50% + 2rem);
        left: 0;
    }

    &::after {
        left: calc(50% + 2rem);
        right: 0;
    }
}
