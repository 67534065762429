/*
All global styles for headings.
*/

@import './variants';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
    line-height: $line-height-tight;
    margin: 0 0 1rem;

    &.base-weight {
        font-weight: normal;
    }

    small,
    .small {
        display: block;
        color: $text-secondary-color;
        font-size: 0.5em;
    }

    @media (min-width: $screen-md) {
        margin-bottom: 1.3rem;
    }
}

h1,
.h1 {
    font-size: 1.8rem;

    @media (min-width: $screen-md) {
        font-size: 3.2rem;
    }
}

h2,
.h2 {
    font-size: 1.5rem;

    @media (min-width: $screen-md) {
        font-size: 2.3rem;
    }
}

h3,
.h3 {
    font-size: 1.33rem;

    @media (min-width: $screen-md) {
        font-size: 1.8rem;
    }
}

h4,
.h4 {
    font-size: 1.2rem;

    p + & {
        margin-top: 1.6rem;
    }

    @media (min-width: $screen-md) {
        font-size: 1.5rem;

        p + & {
            margin-top: 2rem;
        }
    }
}

h5,
.h5 {
    font-size: $font-size-h5-small;
    margin-bottom: 0.5rem;

    @media (min-width: $screen-md) {
        font-size: $font-size-h5-large;
        margin-bottom: 0.8rem;
    }
}

h6,
.h6 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;

    @media (min-width: $screen-md) {
        font-size: 1rem;
        margin-bottom: 0.8rem;
    }
}
