@import '~@bikemap/scss/settings/settings';

.bikemap-logo {
    .bikemap-logo-text {
        fill: $bikemap-logo-bike;
    }

    .bikemap-logo-bike {
        fill: $bikemap-logo-bike;
    }
}
