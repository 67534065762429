/*
Basic styling of text elements.
*/

a {
    text-decoration: none;
    color: $link-color;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
        outline: 0;
    }
}

small,
.small {
    font-size: $font-size-small;
}

strong,
.strong {
    font-weight: bold;
    line-height: inherit;
    color: inherit;
}

em {
    font-style: normal; // We currently don't use an italic font variant
    font-weight: 500; // ...so we emphasize with semibold if this is still used somewhere
    line-height: inherit;
    color: inherit;
}

p,
ul,
ol,
dl {
    margin: 0 0 1rem;
}
