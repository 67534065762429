@import 'components/social-link';

.social-links {
    list-style: none;
    display: flex;
    align-items: center;

    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 0.5em;
        }
    }
}
