@import 'feature-icon';

/*
Feature icons without color.
*/

%feature-icon-secondary {
    .shade {
        fill: $gray-superlight;
        fill-opacity: 0.5;
    }

    .stroke {
        stroke: $gray-lighter;
    }

    &,
    .fill {
        fill: $gray-lighter;
    }
}

.feature-icon-secondary {
    @extend %feature-icon;
    @extend %feature-icon-secondary;
}
