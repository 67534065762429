.layout-primary,
%layout-primary {
    --text-secondary-color: #fff;

    background-color: $actionColor-tertiary;
    color: #fff;
    fill: #fff;

    .btn { // specificity
        &.btn-primary {
            background: rgb(255 255 255 / 20%);

            &:not(.disabled):not(:disabled) {
                &:hover,
                &:active,
                &:focus {
                    background: rgb(255 255 255 / 30%);
                }
            }
        }

        &.btn-flat {
            color: #fff;
            fill: #fff;
        }
    }

    a {
        color: #fff;
        font-weight: 500;
    }
}
