/*
Mixins for reusable color rules.
*/

@mixin content-default {
    color: $text-color;
}

@mixin content-white {
    color: #fff;
}

@mixin content-primary {
    color: $brand-primary;
}

@mixin content-premium {
    color: $brand-premium;
}

@mixin content-danger {
    color: $brand-danger;
}
