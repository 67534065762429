@import '~@bikemap/scss/settings/settings';

/*
Input showing autocomplete options to select.
*/
.autocomplete-field,
.autocomplete-field.multi-search {
    .dropdown-list {
        display: none;
        height: auto;
        overflow-y: auto;
        max-height: 50vh;
    }

    &:not(.loading-spinner) {
        &:focus-within .dropdown-list,
        input:focus ~ .dropdown-list,
        .dropdown-list:focus,
        .dropdown-list:hover {
            display: block;
        }
    }
}
