@import 'components/home/feature-icon';
@import 'components/home/feature-icon-secondary';

.feature,
%feature {
    text-align: center;
    margin-bottom: 2rem;

    h3 {
        font-size: 1.4rem;
    }

    p {
        @extend %bodytext;

        line-height: $line-height-compact;
    }

    @media (min-width: $screen-sm) {
        h3 {
            font-size: 1.7rem;
        }

        &:not(li) {
            position: relative;
            text-align: left;

            [class^='feature-icon'] {
                position: absolute;
                top: 0;
                left: 0;

                ~ * {
                    margin-left: 6rem;
                }
            }
        }
    }
}
