@import 'components/home/feature';

/*
Shows a row of Bikemap KPIs.
*/
.statistics {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;

    .feature {
        position: relative;
        text-align: left;
        width: 15rem;
        flex-shrink: 0;
        padding: 0 1rem 0 3.5rem;
        margin: 0;
    }

    svg {
        width: 2.8rem;
        height: 2.8rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    #premium-feature-icon-map-navigation { // ssset has different dimensions
        transform: scale(1.5);
    }

    .counter {
        @extend %lead;

        font-weight: bold;
    }

    .millions-tag {
        font-weight: normal;
    }

    @media (min-width: $screen-sm) {
        .feature {
            width: auto;
            max-width: 25%;
        }
    }

    @media (min-width: $screen-md) {
        .feature {
            padding: 0 1rem 0 4rem;
        }

        svg {
            width: 3.2rem;
            height: 3.2rem;
            top: 0.1rem;
        }
    }
}
