.section-illustrated {
    padding: 0;

    [class^='wrapper-'] {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-content {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .section-illustration {
        position: relative;
        margin: 0 auto;
    }

    @media (max-width: $screen-xs-max) {
        &.reverse {
            [class^='wrapper-'] {
                flex-direction: column-reverse;
            }
        }
    }

    @media (min-width: $screen-sm) {
        [class^='wrapper-'] {
            flex-direction: row;
        }

        .section-illustration + .section-content,
        .section-content + .section-illustration {
            margin-left: 3rem;
        }

        .section-content {
            :first-child {
                margin-top: 0;
            }
        }

        .section-illustration {
            flex-shrink: 0;

            &.overlapping {
                img {
                    margin-top: -2rem;
                }
            }
        }
    }

    @media (min-width: $screen-lg) {
        .section-illustration + .section-content,
        .section-content + .section-illustration {
            margin-left: 5rem;
        }
    }
}
