/*
CTA section with app download badges.
*/
section.apps-cta-section {
    background-color: $actionColor-tertiary;
    color: $onColor-primary;

    .apps-badges {
        margin-top: 2rem;
    }

    @media (min-width: $screen-sm) {
        .apps-badges {
            font-size: 1.2rem;
        }
    }

    &.apps-cta-big {
        padding: 8rem 0;
    }
}
